var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "back-to-home rounded d-none d-sm-block"
  }, [_c('button', {
    staticClass: "btn btn-icon btn-soft-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.logout
    }
  }, [_c('key-icon', {
    staticClass: "icons"
  })], 1)]), _c('section', {
    staticClass: "bg-home d-flex align-items-center"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-lg-5 col-md-6"
  }, [_c('div', {
    staticClass: "card login-page bg-white shadow rounded border-0"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h4', {
    staticClass: "card-title text-center"
  }, [_vm._v("e-KYC")]), _vm.totalVerification < 2 ? _c('div', [_vm.noCamera ? _c('div', {
    staticClass: "text-center d-flex align-items-center justify-content-center",
    staticStyle: {
      "height": "200px"
    }
  }, [_c('div', [_c('h6', [_vm._v(_vm._s(_vm.$t("activate-camera")))]), _c('a', {
    staticClass: "d-block",
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.relaodPage
    }
  }, [_vm._v(_vm._s(_vm.$t("reload-page")))])]), _c('br')]) : _vm._e(), !_vm.noCamera ? _c('video', {
    ref: "video",
    staticClass: "camera-stream",
    staticStyle: {
      "border-radius": "10px",
      "box-shadow": "0 3px 5px 0 rgba(47, 85, 212, 0.3)"
    }
  }) : _vm._e(), _vm.selfie == null && !_vm.noCamera ? _c('button', {
    staticClass: "btn btn-primary btn-xsm w-100",
    on: {
      "click": function ($event) {
        return _vm.capture('selfie');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("take-selfie")) + " ")]) : _vm._e(), _vm.mykad == null && _vm.selfie != null ? _c('button', {
    staticClass: "btn btn-primary btn-xsm w-100",
    on: {
      "click": function ($event) {
        return _vm.capture('mykad');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("take-mykad")) + " ")]) : _vm._e(), _vm.mykad && _vm.selfie ? _c('button', {
    staticClass: "btn btn-primary btn-xsm w-100",
    on: {
      "click": _vm.faceVerification
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ekyc.verify")) + " ")]) : _vm._e()]) : _vm._e(), _vm.totalVerification >= 2 ? _c('div', [_c('p', [_vm._v(" " + _vm._s(_vm.$t("ekyc.limit")) + " ")]), _vm._m(1), _c('div', {
    staticClass: "text-center mt-5"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.logout
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("navbar.logout")) + " ")])])]) : _vm._e()])])])])])]), _c('Switcher')], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-7 col-md-6"
  }, [_c('div', {
    staticClass: "mr-lg-5"
  }, [_c('img', {
    staticClass: "img-fluid d-block mx-auto",
    attrs: {
      "src": "/images/user/login.svg",
      "alt": ""
    }
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', [_c('li', [_vm._v("+603 - 6263 9990")]), _c('li', [_vm._v("admin@awarisgroup.com")])]);
}]

export { render, staticRenderFns }